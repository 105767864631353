import './SettingsValue.css'
import './DeviceView.css'
import {SettingsChangesContext, SettingsChangesErrContext} from './SettingsPage.js'
import { useState, useEffect, useContext } from 'react';


function SettingsValue({name, text, postText, wideTextbox}) {
    const {settingsChanges, setSettingsChanges} = useContext(SettingsChangesContext);
    const {settingsChangesErr, setSettingsChangesErr} = useContext(SettingsChangesErrContext);

    const changeSetting = event => {
        const {name, value} = event.target;
        setSettingsChanges(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    const [textClassName, setTextClassName]  = useState('input_target');

    useEffect(() => {
        console.log(wideTextbox);
        if(wideTextbox)
            setTextClassName('value_input_wide');
        else
            setTextClassName('value_input');
    }, [wideTextbox]);


    return (
        <div className="SettingsValueContainer">
            {settingsChangesErr[name] && 
                <p className="ErrorText">{settingsChangesErr[name]}</p>
            }
            {text}
            <input
                type="text"
                name={name}
                className={textClassName}
                value={settingsChanges[name]}
                onChange={(e) => { changeSetting(e); }}/>
            {postText}
        </div>
    );
}

SettingsValue.defaultProps = {
    name: "", 
    text: "", 
    postText: "", 
    wideTextbox: false
};

export default SettingsValue;

