import './Dashboard.css'
import DeviceView from './DeviceView.js'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from "react-router-dom";


function Dashboard_ListItem({name, uid, onClick}) {
    return (
        <button className="Device-list-item" onClick={() => onClick(uid)}>
            <div>{name}</div>
            <div className="Device-list-item-uid">uid: {uid}</div>
        </button>
    );
}


function Dashboard() {
    const navigate = useNavigate();

    const [Devices, setDevices] = useState([]);

    useEffect(() => {
        updateList();
    }, []);

    async function updateList() {
        try {
            let url = window.location.origin + '/api/devices';
            let response = await fetch(url);
            let data = await response.json();

            setDevices(data.devices);
        } catch(error) {
            console.log(error);
        }
    }


    const device_items = Devices.map(device =>
        <li key={device.uid}>
            <Dashboard_ListItem name={device.name} uid={device.uid} onClick={listItem_click}/>
        </li>
    );
    
    function listItem_click(uid) {
        navigate(`/dashboard/${uid}`);
    }


    return (
        <div className="Dashboard">
            <h1>Dashboard</h1>
            <div className="Dashboard-children">
                <ul className="Device-list">{device_items}</ul>
                <div> 
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
