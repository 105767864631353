export class BoundsI {
    constructor(lowerBound, upperBound) {
        this.lowerBound = lowerBound;
        this.upperBound = upperBound;
    }

    validate(value) {
        let v = new IsNumber();
        let s = v.validate(value);
        if(s.length > 0)
            return s;

        let n = Number(value);
        if(n > this.upperBound || n < this.lowerBound)
            return "Should be between " + this.lowerBound + " and " + this.upperBound;
        return "";
    }
}

export class LessThan {
    constructor(bound) {
        this.bound = bound;
    }

    validate(value) {
        let v = new IsNumber();
        let s = v.validate(value);
        if(s.length > 0)
            return s;

        let n = Number(value);
        if(n >= this.bound)
            return "Should be less than " + this.bound;
        return "";
    }
}


export class NonNeg {
    validate(value) {
        let v = new IsNumber();
        let s = v.validate(value);
        if(s.length > 0)
            return s;

        let n = Number(value);
        if(n < 0)
            return "Should be a non-negative number";
        return "";
    }
}

export class IsInt {
    validate(value) {
        let v = new IsNumber();
        let s = v.validate(value);
        if(s.length > 0)
            return s;

        let n = Number(value);
        if(!Number.isInteger(n))
            return "Not an integer";
        return "";
    }
}

export class IsNumber {
    validate(value) {
        if(value.length == 0)
            return "Not a number";

        let n = Number(value);
        if(isNaN(n))
            return "Not a number";
        return "";
    }
}
