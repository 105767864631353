import './DeviceView.css'
import SettingsValue from './SettingsValue.js'
import SettingsDisplayPage from './SettingsDisplayPage.js'
import SettingsEditPage from './SettingsEditPage.js'
import EditableText from './EditableText.js'
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef, useContext, createContext } from 'react';
import checkimg from './check.png'
import ximg from './x.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {DeviceSettingsContext, ServerSettingsContext, ServerSettingsErrContext} from './DeviceView.js';
import {settingsObj, settingsValidators} from './Settings.js';


const defaultRangeStrs = {
    ph: ['', '', ''],
    orp: ['', '', ''],
};
export const RangeStrsContext = createContext(defaultRangeStrs);

export const SettingsChangesContext = createContext(settingsObj);
export const SettingsChangesErrContext = createContext(settingsObj);


export default function SettingsPage({refreshData, Device_Updated, Device_LastContact}) {
    const { uid } = useParams();

    const {deviceSettings, setDeviceSettings} = useContext(DeviceSettingsContext);
    const {serverSettings, setServerSettings} = useContext(ServerSettingsContext);

    const [settingsChanges, setSettingsChanges] = useState(settingsObj);
    const [settingsChangesErr, setSettingsChangesErr] = useState(settingsObj);

    const changeServerSetting = event => {
        const {name, value} = event.target;
        setServerSettings(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };


    const [DeviceMode, setDeviceMode] = useState(true);

    const [ChangesMade, setChangesMade] = useState(false);
    const [ErrorState, setErrorState] = useState(false);




    function updateChangesMade() {
        let changes = false;

        for(const [key, val] of Object.entries(serverSettings)) {
            if(serverSettings[key] != settingsChanges[key])
                changes = true;
        }

        if(changes != ChangesMade)
            setChangesMade(changes);
    }

    function validateSettingsChanges() {
        var n;
        var newErr = {...settingsChangesErr};
        var errors = false;

        for(const [key, val] of Object.entries(settingsValidators)) {
            newErr[key] = "";
            for(var validatorKey in val) {
                let setting = settingsChanges[key];
                let s = val[validatorKey].validate(setting);
                if(s.length > 0) {
                    errors = true;
                    newErr[key] = s;
                    break;
                }
            }
        }
        
        setErrorState(errors);
        setSettingsChangesErr(newErr);
    }

    useEffect(() => {
        validateSettingsChanges();
        updateChangesMade();
    }, [settingsChanges]);

    useEffect(() => {
        setSettingsChanges({...serverSettings});
    }, [serverSettings]);


    async function saveChanges() {
        if(ErrorState) {
            console.error("Error, can't validate");
            return;
        }

        let data = {...settingsChanges};

        let url = window.location.origin + '/api/device/' + uid;
        try {
            const resp = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
        } catch(error) {
            console.log(error);
            return;
        }

        refreshData();
        setDeviceMode(true);
    }

    function discardChanges() {
        resetToOriginals();
        setDeviceMode(true);
    }


    function resetToOriginals() {
        setSettingsChanges({...serverSettings});
    }


    function updateRangeStrs() {
        let strs = {...rangeStrs}

        let phtarget = Number(deviceSettings.target_ph);
        let phrange0 = Number(deviceSettings.target_ph_range0);
        let phrange1 = Number(deviceSettings.target_ph_range1);
        let phrange2 = Number(deviceSettings.target_ph_range2);

        let ph0 = Math.round((phtarget + phrange0)*100)/100;
        let ph1 = Math.round((phtarget + phrange1)*100)/100;
        let ph2 = Math.round((phtarget + phrange2)*100)/100;

        strs.ph[0] = "pH Range: " + ph0 + " - " + ph1;
        strs.ph[1] = "pH Range: " + ph1 + " - " + ph2;
        strs.ph[2] = "pH Range: " + ph2 + "+";


        let orptarget = Number(deviceSettings.target_orp);
        let orprange0 = Number(deviceSettings.target_orp_range0);
        let orprange1 = Number(deviceSettings.target_orp_range1);
        let orprange2 = Number(deviceSettings.target_orp_range2);

        let orp0 = Math.round((orptarget - orprange0)*10)/10;
        let orp1 = Math.round((orptarget - orprange1)*10)/10;
        let orp2 = Math.round((orptarget - orprange2)*10)/10;

        strs.orp[0] = "ORP Range: " + orp1 + " - " + orp0 + " mV";
        strs.orp[1] = "ORP Range: " + orp2 + " - " + orp1 + " mV";
        strs.orp[2] = "ORP Range: " + orp2 + "- mV";

        setRangeStrs(strs);
    }

    useEffect(() => {
        updateRangeStrs();
    }, [deviceSettings]);

    const [rangeStrs, setRangeStrs] = useState(defaultRangeStrs);




    return (

        <div>
            {DeviceMode ? (
                <div className="EditOptionsContainer">
                    <button onClick={() => { setDeviceMode(false); }}>
                        Edit Settings
                    </button>
                </div>
            ) : (
                <div className="EditOptionsContainer">
                    <button 
                        onClick={saveChanges} 
                        disabled={!ChangesMade || ErrorState}>
                        Save
                    </button>
                    <button
                        onClick={discardChanges}>
                        Cancel
                    </button>
                </div>
            )}

            {DeviceMode ? (
                <div>
                    <div className="ContactInfo">
                        <div className="UpdateContainer">
                            <img className="UpdateImage" src={Device_Updated ? checkimg : ximg}/>
                            <p>{Device_Updated ? "Up to Date" : "Out of Date"}</p>
                        </div>
                        <p>Last Contact: {Device_LastContact}</p>
                    </div>
            
                    <SettingsDisplayPage/>
                </div>

            ) : (

                <SettingsChangesContext.Provider value={{settingsChanges, setSettingsChanges}}>
                <SettingsChangesErrContext.Provider value={{settingsChangesErr, setSettingsChangesErr}}>
                <RangeStrsContext.Provider value={rangeStrs}>

                <div>
                    <div className="ContactInfo">
                        <button 
                            onClick={resetToOriginals} 
                            disabled={!ChangesMade}>
                            Reset to Server's Latest Settings
                        </button>
                    </div>

                    <SettingsEditPage/>

                </div>
                </RangeStrsContext.Provider>
                </SettingsChangesErrContext.Provider>
                </SettingsChangesContext.Provider>
            )}

        </div>

    );
}
