import './ReadingsPage.css';
import './DeviceView.css';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef, useContext, createContext } from 'react';
import { LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useInterval from './UseInterval.js';

dayjs.extend(utc);


export default function ReadingsPage() {
    const { uid } = useParams();

    async function pullReadings() {
        if(!uid)
            return;

        let url = window.location.origin + '/api/device/' + uid + '/readings';
        let data = {};
        if(viewLen == 'Last Week') { 
            console.log("Last week pull");
            var start = dayjs().startOf('day').subtract(6, 'day');
            var end = dayjs().add(1, 'day').startOf('day');
            data = {
                startTime: dayjs.utc(start).format('YYYYMMDDHHmmss'),
                endTime: dayjs.utc(end).format('YYYYMMDDHHmmss')
            };
        }
        else if(viewLen == 'Last Day') {
            console.log("Last day pull");
            var start = dayjs().startOf('day');
            var end = dayjs().add(1, 'day').startOf('day');
            data = {
                startTime: dayjs.utc(start).format('YYYYMMDDHHmmss'),
                endTime: dayjs.utc(end).format('YYYYMMDDHHmmss')
            };
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            let json = await response.json();

            for(const r of json.ph) {
                r.timestamp = dayjs(r.timestamp);
                r.numTimestamp = r.timestamp.valueOf();
            }
            setPHReadings(json.ph);

            for(const r of json.orp) {
                r.timestamp = dayjs(r.timestamp);
                r.numTimestamp = r.timestamp.valueOf();
            }
            setORPReadings(json.orp);

            for(const r of json.temp) {
                r.timestamp = dayjs(r.timestamp);
                r.numTimestamp = r.timestamp.valueOf();
            }
            setTempReadings(json.temp);

            for(const r of json.poolTemp) {
                r.timestamp = dayjs(r.timestamp);
                r.numTimestamp = r.timestamp.valueOf();
            }
            setPoolTempReadings(json.poolTemp);

        } catch(error) {
            //console.log(error);
            return;
        }
    }

    useInterval(() => {
        pullReadings();
    }, 5000);


    const [PHReadings, setPHReadings] = useState([]);
    const [ORPReadings, setORPReadings] = useState([]);
    const [TempReadings, setTempReadings] = useState([]);
    const [PoolTempReadings, setPoolTempReadings] = useState([]);

    function getPHDomain() {
        if(PHReadings.length > 0)
            return ['dataMin', 'dataMax']
        else
            return [7, 8];
    };

    function getORPDomain() {
        if(ORPReadings.length > 0)
            return ['dataMin', 'dataMax']
        else
            return [450, 1000];
    };

    function getTempDomain() {
        if(TempReadings.length > 0)
            return ['dataMin', 'dataMax']
        else {
            return [32, 110];
        }
    };

    function getPoolTempDomain() {
        if(PoolTempReadings.length > 0)
            return ['dataMin', 'dataMax']
        else {
            return [32, 110];
        }
    };


    const formatAxisTimestamp = (millis) => {
        let d = dayjs(millis);
        if(viewLen == 'Last Week')
            return d.format('MM/DD');
        else if(viewLen == 'Last Day')
            return d.format('H:mm');
    };

    const formatLabelTimestamp = (millis) => {
        let d = dayjs(millis);
        return d.format('MM/DD/YY HH:mm:ss');
    };


    const [domainStart, setDomainStart] = useState();    
    const [domainEnd, setDomainEnd] = useState();


    const [viewLen, setViewLen] = useState('Last Week');

    useEffect(() => {
        if(viewLen == 'Last Day') {
            setDomainStart(dayjs().startOf('day').valueOf());
            setDomainEnd(dayjs().add(1, 'day').startOf('day').valueOf());
        }
        if(viewLen == 'Last Week') {
            setDomainStart(dayjs().startOf('day').subtract(6, 'day').valueOf());
            setDomainEnd(dayjs().add(1, 'day').startOf('day').valueOf());
        }
        pullReadings();
    }, [viewLen]);

    const viewOptionsChange = (e) => {
        setViewLen(e.target.value);
    }


    const dateTicks = () => {
        if(viewLen == 'Last Week') {
            let SPACETIME = 3600000*24;

            //let firstHour = Math.floor(domainStart / SPACETIME) * SPACETIME; 
            let firstTime = domainStart;
            let arr = [firstTime];
            let time = firstTime + SPACETIME;
            while(time <= domainEnd) {
                arr.push(time);
                time += SPACETIME;
            }

            return arr;
        }
        else if(viewLen == 'Last Day') {
            let SPACETIME = 3600000;

            //let firstHour = Math.floor(domainStart / SPACETIME) * SPACETIME; 
            let firstTime = domainStart;
            let arr = [firstTime];
            let time = firstTime + SPACETIME;
            while(time <= domainEnd) {
                arr.push(time);
                time += SPACETIME;
            }

            return arr;
        }
    }

    return (
        <div>
            <div className="viewOptions">
                <p>Last Week:</p>
                <input type="radio" name="graphTime" value="Last Week"
                        checked={viewLen === "Last Week"}
                        onChange={viewOptionsChange}/>
                <p>Last Day:</p>
                <input type="radio" name="graphTime" value="Last Day"
                        checked={viewLen === "Last Day"}
                        onChange={viewOptionsChange}/>
            </div>

            <h3 className="graphTitle TitleText">pH</h3>
            <div className="graphContainer">
                <ResponsiveContainer>
                    <LineChart data={PHReadings}>
                        <Line type="monotone" dataKey="reading" stroke="#8884d8"/>
                        <YAxis domain={getPHDomain()}/>
                        <XAxis type='number' dataKey="numTimestamp" 
                            domain={[domainStart, domainEnd]}
                            ticks={dateTicks()}
                            tickFormatter={(tick) => formatAxisTimestamp(tick)}/>
                        <Tooltip labelFormatter={(label) => formatLabelTimestamp(label)}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <h3 className="graphTitle TitleText">ORP</h3>
            <div className="graphContainer">
                <ResponsiveContainer>
                    <LineChart data={ORPReadings}>
                        <Line type="monotone" dataKey="reading" stroke="#8884d8"/>
                        <YAxis domain={getORPDomain()}/>
                        <XAxis type='number' dataKey="numTimestamp" 
                            domain={[domainStart, domainEnd]}
                            ticks={dateTicks()}
                            tickFormatter={(tick) => formatAxisTimestamp(tick)}/>
                        <Tooltip labelFormatter={(label) => formatLabelTimestamp(label)}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <h3 className="graphTitle TitleText">Pool Temperature</h3>
            <div className="graphContainer">
                <ResponsiveContainer>
                    <LineChart data={PoolTempReadings}>
                        <Line type="monotone" dataKey="reading" stroke="#8884d8"/>
                        <YAxis domain={getPoolTempDomain()}/>
                        <XAxis type='number' dataKey="numTimestamp" 
                            domain={[domainStart, domainEnd]}
                            ticks={dateTicks()}
                            tickFormatter={(tick) => formatAxisTimestamp(tick)}/>
                        <Tooltip labelFormatter={(label) => formatLabelTimestamp(label)}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <h3 className="graphTitle TitleText">Dispenser Unit Temperature</h3>
            <div className="graphContainer">
                <ResponsiveContainer>
                    <LineChart data={TempReadings}>
                        <Line type="monotone" dataKey="reading" stroke="#8884d8"/>
                        <YAxis domain={getTempDomain()}/>
                        <XAxis type='number' dataKey="numTimestamp" 
                            domain={[domainStart, domainEnd]}
                            ticks={dateTicks()}
                            tickFormatter={(tick) => formatAxisTimestamp(tick)}/>
                        <Tooltip labelFormatter={(label) => formatLabelTimestamp(label)}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
