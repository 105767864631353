import './EditableText.css'
import { useState, useEffect } from 'react';

function EditableText({text, setText, className}) {
    const [editMode, setEditMode] = useState(false);
    const [internalText, setInternalText] = useState('');

    useEffect(() => {
        setInternalText(text);
    }, [text]);

    function enterEditMode() {
        setEditMode(true);
    }

    function leaveEditMode() {
        setText(internalText);
        setEditMode(false);
    }

    return (
        <div className={className}>
            {editMode ? 
                (
                    <div className="TextContainer">
                        <input className="TextDisplay" type="text" value={internalText} 
                            onChange={(e) => { setInternalText(e.target.value); }}/>
                        <button className="EditModeButton"
                            onClick={leaveEditMode}>Done</button>
                    </div>
                ) : (
                    <div className="TextContainer"> 
                        <h1 className="TextDisplay">{text}</h1>
                        <button className="EditModeButton"
                            onClick={enterEditMode}>Edit</button>
                    </div>
                )
            }
        </div>
    );
}

export default EditableText;

